/** @jsx jsx */
import React, { type MutableRefObject, memo, type ReactNode } from 'react';
import { css, keyframes, jsx, cssMap } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useCellHoverContext } from './hover-wrapper/index.tsx';

type MinimumWidth = 'narrow';

export type Props = {
	content?: ReactNode;
	children: ReactNode;
	contentRef?: MutableRefObject<HTMLElement | null>;
	minimumWidth?: MinimumWidth;
};

/**
 * Utility function to get the closest element that is truncated.
 * This is required since some components already have pre-existing refs set up or
 * do not allow refs to be set on those components.
 * If there is no element that is truncated, return the passed in element.
 * @param el
 * @returns
 */
const getClosestTextElement = (el: Element): Element | null => {
	if (!el || !el.children || el.children.length === 0) return el;

	const elements = [...el.children];

	for (const e of elements) {
		if (e.clientWidth !== e.scrollWidth) {
			return e;
		}

		for (const child of e.children) {
			elements.push(child);
		}
	}

	return el;
};

export const HoverPopover = memo(({ content, children, contentRef, minimumWidth }: Props) => {
	const isHovered = useCellHoverContext();

	if (!isHovered || !content) {
		return children;
	}

	const contentWithPopover = (
		<>
			{children}
			{content && (
				<div css={[containerStyles, containerVariantStyles[minimumWidth ?? 'content']]}>
					<div
						data-testid="native-issue-table.common.ui.hover-popover.hover-popover-content"
						aria-hidden
						css={popoverStyles}
					>
						{content}
					</div>
				</div>
			)}
		</>
	);

	const elementWithTextContent = contentRef?.current
		? getClosestTextElement(contentRef?.current)
		: undefined;

	const clientWidth = elementWithTextContent?.clientWidth || 0;
	const scrollWidth = elementWithTextContent?.scrollWidth || 0;

	// If contentRef is not provided then the popover should always be visible on hover.
	if (!contentRef?.current || scrollWidth > clientWidth) {
		return contentWithPopover;
	}
	return children;
});

const fadeIn = keyframes({
	from: {
		opacity: '0',
	},
	to: {
		opacity: '100',
	},
});

const containerStyles = css({
	paddingTop: token('space.050'),
	position: 'absolute',
	top: '40px',
	animationName: `${fadeIn}`,
	opacity: '0',
	animationDuration: '0.1s',
	animationTimingFunction: 'ease-in',
	animationFillMode: 'forwards',
	maxWidth: '300px',
	zIndex: 9999,
});

const containerVariantStyles = cssMap({
	narrow: {
		minWidth: '200px',
		maxWidth: '300px',
	},
	content: {
		whiteSpace: 'nowrap',
	},
});

const popoverStyles = css({
	backgroundColor: token('elevation.surface.overlay'),
	paddingInline: token('space.150'),
	paddingBlock: token('space.075'),
	borderRadius: token('border.radius'),
	boxShadow: token('elevation.shadow.overlay'),
	fontBody: token('font.body'),
	fontWeight: token('font.weight.regular'),
	overflowWrap: 'break-word',
	maxHeight: '300px',
	overflowY: 'scroll',
});
